<template>
  <div class="card cardlabel">
    <div class="expander" @click="isExpand = !isExpand">
      <h2>Fast Launch</h2>
      <font-awesome-icon
        icon="fa-solid fa-chevron-down"
        :class="{ 'icon-rotate': isExpand === true }"
      />
    </div>
    <div
      id="active-contracts-card"
      class="card-body px-4 pb-3"
      :class="{ 'is-expand': isExpand === true }"
    >
      <!-- <div class="d-flex justify-content-between align-items-center px-4 pb-3">
        <h2 class="m-0">Fast Launch</h2>
        <div class="d-flex flex-row justify-content-between align-items-center" style="display: none !important;">
          <div>Solidity version:</div>
          <vs-select
            placeholder="Select solidity version"
            v-model="contractConfig.compilerVersion"
            v-if="Object.keys(contractConfig.compilerVersions).length > 0"
          >
            <vs-option
              v-for="version in Object.keys(contractConfig.compilerVersions)"
              :key="version"
              :label="version"
              :value="contractConfig.compilerVersions[version]"
            >
              {{ version }}
            </vs-option>
          </vs-select>
          <div
            class="button-text d-flex justify-content-center align-items-center"
          >
            <a style="width: 80px" @click="handleCompile()" class="btn-theme"
              >Upload</a
            >
          </div>
        </div>
      </div> -->
      <div class="">
        <div class="d-flex flex-wrap mt-2 mb-2">
          <div class="col-lg-12 pe-6">
            <div class="">
              <label class="form-label">Owner PK</label>
              <input
                type="text"
                v-model="txConfig.ownerPK"
                class="form-control"
              />
            </div>
            <div class="">
              <label class="form-label">Tax Wallet PK</label>
              <input
                type="text"
                @input="(event) => updateTaxWallet(event.target.value)"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-2 mb-4">
          <div class="col-lg-6 pe-3">
            <div class="">
              <label class="form-label">Name</label>
              <input
                type="text"
                @input="(event) => updateName(event.target.value)"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="">
              <label class="form-label">Symbol</label>
              <input
                type="text"
                @input="(event) => updateSymbol(event.target.value)"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-2 mb-2">
          <div class="col-lg-12 pe-6">
            <div class="">
              <label class="form-label">Socials</label>
              <textarea
                v-model="socials"
                class="form-control"
                style="height: 100px; width: 100%"
              ></textarea>
            </div>
          </div>
        </div>
        <vue-monaco-editor
          class="form-control"
          style="padding: 0px !important"
          v-model="contractConfig.code"
          theme="vs-light"
          height="400px"
          language="sol"
          :options="{
            automaticLayout: true,
            minimap: { enabled: false },
            readOnly: true,
          }"
        />
      </div>
      <div
        class="py-4"
        v-if="Object.keys(contractConfig.compiledContracts).length > 0"
      >
        <div class="w-100 button-text d-flex justify-content-between items-center">
          <vs-select
            placeholder="Select contract name"
            v-model="contractConfig.contractName"
          >
            <vs-option
              v-for="name in Object.keys(contractConfig.compiledContracts)"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </vs-option>
          </vs-select>
          {{ contractConfig.deployedContract }}
          <a
              style="width: 80px"
              @click="handleVerify()"
              class="btn-theme"
              v-if="!isVerifying"
              >Verify</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
        </div>
        <div>{{ status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
// import Utils from '@/helpers/Utils';
import Web3 from '@/helpers/Web3';
// import Zerion from '@/helpers/Zerion';
// import Vue from 'vue';
// import Sample from './sample.json';
import { VueMonacoEditor } from '@guolao/vue-monaco-editor';
import { getCompilerVersions } from '@agnostico/browser-solidity-compiler';
import { utils, Wallet } from 'ethers';
import RLP from 'rlp';
import axios from 'axios';
import querystring from 'querystring';

export default {
  name: 'ContractEditor',
  components: { VueMonacoEditor },
  computed: {
    ...mapGetters({
      liveList: 'watches/liveList',
      pastList: 'watches/pastList',
      contractConfig: 'contracts/config',
      txConfig: 'transactions/config',
    }),
  },
  watch: {},
  async mounted() {
    this.setCompilerVersions((await getCompilerVersions()).releases);
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      fetch: 'watches/fetch',
      delete: 'watches/delete',
      setContractName: 'contracts/setContractName',
      setCompilerVersions: 'contracts/setCompilerVersions',
      compileContract: 'contracts/compileContract',
      setDeployedContract: 'contracts/setDeployedContract',
      async handleVerify() {
        this.isVerifying = true;
        const chainid = 1;
        const ETHERSCAN_API_KEY = 'JCWP6W46Y3CC7ZU8Q48PRKEF763JYZADGQ';
        const sourceCode = `/*
${this.socials}
*/
${this.contractConfig.code}`;
        console.log('sourceCode :>> ', sourceCode);
        const receipt = (
          await axios.post(
            `https://api.etherscan.io/v2/api?chainid=${chainid}`,
            querystring.stringify({
              optimizationUsed: '1',
              runs: '200',
              apikey: ETHERSCAN_API_KEY,
              module: 'contract',
              action: 'verifysourcecode',
              contractaddress: this.contractConfig.deployedContract,
              sourceCode,
              codeformat: 'solidity-single-file',
              contractname: `${this.contractConfig.contractName}`,
              compilerversion: this.contractConfig.compilerVersion.slice(8, -3),
            })
          )
        ).data;
        const guid = receipt.result;
        if (receipt.status === '0') {
          this.status = receipt.result;
          this.isVerifying = false;
          return;
        }
        if (guid === 'Contract source code already verified') {
          this.isVerifying = false;
          return;
        }
        console.log('guid :>> ', guid);
        let verifiedOrFailed = false;
        let maxTry = 30;
        do {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const result = await axios.get(
            `https://api.etherscan.io/v2/api?chainid=${chainid}&module=contract&action=checkverifystatus&guid=${guid}&apikey=${ETHERSCAN_API_KEY}`
          );
          this.status = result.data.result;
          if (
            this.status === 'Pass - Verified' ||
            this.status === 'Already Verified'
          ) {
            verifiedOrFailed = true;
            this.status = '';
          } else if (this.status.includes('Fail')) {
            verifiedOrFailed = true;
          }
        } while (!verifiedOrFailed && maxTry-- > 0);
        this.isVerifying = false;
      },
    }),
    getNetwork() {
      return Web3.getNetwork();
    },
    async handleCompile() {
      this.compileContract();
    },
    updateName(name) {
      this.contractConfig.code = this.contractConfig.code.replace(
        /string private constant _name = unicode"[^"]*";/g,
        `string private constant _name = unicode"${name}";`
      );
    },
    updateSymbol(symbol) {
      this.contractConfig.code = this.contractConfig.code.replace(
        /string private constant _symbol = unicode"[^"]*";/g,
        `string private constant _symbol = unicode"${symbol}";`
      );
    },
    async updateTaxWallet(taxWalletPk) {
      const wallet = new Wallet(taxWalletPk);
      const nonce = await Web3.getNonce(wallet.address);
      const inputArr = [wallet.address, nonce];
      const rlpEncoded = RLP.encode(inputArr);
      const contractAddressLong = utils.keccak256(rlpEncoded);
      const taxWallet = `0x00${contractAddressLong.substring(26)}`;
      this.contractConfig.code = this.contractConfig.code.replace(
        /address private _taxWallet = address\([^)]*\);/g,
        `address private _taxWallet = address(${taxWallet});`
      );
    },
  },
  data() {
    return {
      isVerifying: false,
      status: '',
      isExpand: true,
      socials: '',
    };
  },
};
</script>

<style scoped>
.expander {
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-rotate {
  transform: rotate(180deg);
}

.card-body {
  padding: 0px;
  overflow: hidden;
  height: 0px;
}

.is-expand {
  height: auto;
  padding: 1.5rem;
  padding-top: 0px;
  overflow: auto;
}

.mobile-mt {
  margin-top: 0px;
}

.mobile-gap {
  gap: 0px;
}

@media (max-width: 992px) {
  .mobile-mt {
    margin-top: 12px;
  }
  .mobile-gap {
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .sticky-top {
    position: unset;
  }
}
</style>
